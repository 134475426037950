.way-info {
  margin-top: 30px;
  width: 70%;
  margin-left: 15%;
}

.title-box {
  padding-top: 20px;
  text-align: center;
  padding-bottom: 20px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
}
.title-box .info-box {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

.nav-tabs.vertical {
  flex-direction: column;
  border: 1px solid #dee2e6;
}

.nav-tabs.vertical .nav-link {
  border: 1px solid transparent;
  border-radius: 0px;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  background: none;
}

.nav-pills .nav-link.active {
  background: none !important;
  box-shadow: none;
}

.nav-tabs.vertical .active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #fff;
}

.icon {
  text-align: center;
  line-height: 50px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  float: left;
  font-size: 28px;
}

.icon .svg {
  width: 45px;
  height: 45px;
}

.icon img {
  width: 30px;
  height: 30px;
}

.left-info {
  position: relative;
  color: black !important;
  right: 80px;
  top: 50px;
}

.right-info {
  color: black !important;
  position: absolute;
  left: 120px;
  width: 500px;
}

.nav-pills.flex-column .nav-link {
  position: relative;
  width: fit-content;
  margin: 10px 0rem;
  border-radius: 50%;
}

.nav-pills.flex-column .nav-link:not(:last-child)::before {
  display: block;
  position: absolute;
  content: '';
  border: 1px solid #495057;
  top: 100%;
  height: 8rem;
  left: 50%;
}

.arrow-svg {
  width: '32%';
  height: 40px;
}

@media only screen and (max-width: 800px) {
  .way-info {
    margin-top: 30px;
    width: 90%;
  }

  .title-box {
    padding-top: 20px;
    text-align: center;
    padding-bottom: 20px;
    border-bottom: 0.5px solid black;
  }
  .title-box .info-box {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
  }
  .right-info {
    width: 250px;
  }

  .arrow-svg {
    width: 100px;
    height: 40px;
  }
}
