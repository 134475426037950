.header > h5 {
  color: #1c365c;
  font-weight: bolder;
  padding: 0;
}
.header > span {
  color: #1c365c;
  font-size: 14px;
  font-weight: bolder;
  padding: 0;
}

.track_input > div > span {
  font-size: 10px;
  color: #1c365c;
  font-weight: 600;
  padding: 0;
}
.track_input > div > input {
  height: 30px;
  padding: 0;
}
.waybill_info {
  border: 1px solid black;
  padding: 10px;
  display: flex;
}

.waybill_info > div > span {
  padding-bottom: 10px;
  color: #1c365c;
  font-size: 14px;
  font-weight: bolder;
}
.waybill_info > div > h6 {
  color: #1c365c;
  margin-top: 10px;
}
.divider {
  border-bottom: 1px solid gray;
  padding: 10px 0px;
  margin-bottom: 35px;
}
.info_details {
  border: 1px solid black;
  margin-top: 20px;
}
.info_details > .date_box {
  border-bottom: 1px solid black;
  display: flex;
  padding: 10px;
}
.info_details > .date_box > .icon {
  font-size: 30px;
  margin-right: 20px;
}
.info_details > .date_box > div > span {
  color: #1c365c;
  font-size: 14px;
  font-weight: bolder;
}
.info_details_left {
  border-right: 1px solid black;
  min-height: 400px;
  padding-top: 30px;
  padding-left: 30px;
}
.info_details_right {
  padding-top: 30px;
  padding-left: 30px;
  height: 100px !;
}
.info_row_header > div > span {
  color: #1c365c;
  font-size: 14px;
  font-weight: bolder;
}
.info_row_content > div > span {
  font-size: 14px;
}

.nav-pills .nav-link {
  background: none !important;
  box-shadow: none !important;
}

.nav-tabs.vertical {
  color: #495057;
}

.nav-pills.flex-column .nav-link {
  position: relative;
  width: fit-content;
  margin: 35px 0rem;
  border-radius: 50%;
  height: 3rem;
}

.nav-pills.flex-column .nav-link:not(:last-child)::before {
  display: block;
  position: absolute;
  content: '';
  border: 1px solid #495057;
  top: 90%;
  height: 3rem !important;
  left: 50%;
}

.icon {
  height: 35px !important;
}

@media screen and (max-width: 825px) and (min-width: 414px) {
  .info_row_header > div > span,
  .info_row_content > div > span {
    font-size: 12px;
  }
}

@media only screen and (max-width: 414px) {
  .track_input > button {
    font-size: 12px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .info_details_left {
    border-right: 0px;
    border-bottom: 1px solid black;
  }
}
