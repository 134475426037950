.tracking_container {
  //background-color: #f6f7f9;
  padding: 1rem;
  min-height: 300px;
}

.tracking_title {
  font-weight: bold;
}

.tracking_search_row {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  min-height: 80px;
  padding: 1rem;
}

.tracking_search_label {
  margin: 0;
  padding: 0;
  text-align: right;
}

.tracking_search_btn {
  width: 150px;
}

.tabs_container {
  margin-top: 2rem;
  background-color: #ffffff;
}

.main_info_container {
  //background-color: rgba(254, 254, 254, 0.789);
  padding: 10px;
  p {
    font-size: 13px;
  }
}

.main_info_title {
  text-align: right;
}
