.Header {
  flex: 1;
}

.Container {
  padding-top: 15px;
}

.Item {
  flex: 1;
  margin-bottom: 3px;
}

.Container p,
h4,
h2 {
  padding: 0;
  margin: 0;
}

.Container h4 {
  font-size: 14px;
}

.Container p {
  font-size: 14px;
  color: black;
}

.Divider {
  border-bottom: 1px solid black;
}

.CenterBarcode {
  display: flex;
  justify-content: center;
}

.Container {
  display: flex;
}

.LeftContainer {
  padding: 0px 1.8%;
  padding-left: 4%;
  width: 44%;
  display: flex;
  flex-direction: column;
  border-right: 2px dotted black;
}
.RightContainer {
  padding-left: 3%;
  width: 38%;
}
.TitleContainer {
  display: flex;
  justify-content: space-between;
  padding: 4px 0px;
  width: 100%;
}

.BarCodeContainer {
  display: flex;
  justify-content: right;
  padding: 4px 0px;
}
.TitleContainer {
  display: flex;
  justify-content: space-between;
  padding: 4px 0px;
  width: 100%;
}

.BarCodeContainer {
  display: flex;
  justify-content: right;
  padding: 4px 0px;
  width: 100%;
}
.TitleContainer {
  display: flex;
  justify-content: space-between;
  padding: 8px 0px;
  width: 100%;
}

.BarCodeContainer {
  display: flex;
  justify-content: right;
  padding: 8px 0px;
  width: 100%;
}
.TitleContainer {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
  width: 100%;
}

.BarCodeContainer {
  display: flex;
  justify-content: right;
  padding: 10px 0px;
  width: 100%;
}
.TitleContainer {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
  width: 100%;
}

.BarCodeContainer {
  display: flex;
  justify-content: right;
  padding: 10px 0px;
  width: 100%;
}

.ContainerHeader {
  margin-top: 12px;
  .Container {
    display: flex;
    justify-content: space-between;
    .LogoContainer {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: flex-start;
      gap: 1;
      h1 {
        font-weight: bold;
        font-size: 23px;
      }
    }

    .LogoContainerNew {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items:center;
      gap: 1;
      h1 {
        font-weight: bold;
        font-size: 23px;
      }
    }
  }

  .CodesContainer {
    display: flex;
    justify-content: space-between;
    h2 {
      font-weight: bold;
    }
  }

  .Header {
    flex: 1;
  }

  .Item {
    flex: 1;
    margin-bottom: 3px;
  }

  .Container p,
  h4,
  h2,
  h3 {
    padding: 0;
    margin: 0;
  }

  .Container img {
    width: 150px;
    height: 80px;
  }

  // .Container h4 {
  //   font-size: 26px;
  // }

  .Container h3 {
    font-size: 23px;
  }

  .Container h2 {
    font-size: 20px;
  }

  .Container p {
    font-size: 18px;
  }

  .Divider {
    border-bottom: 1px solid black;
  }

  .CenterBarcode {
    display: flex;
    justify-content: center;
  }

  .Total {
    display: flex;
    justify-content: flex-end;
  }

  .Footer {
    display: flex;
    justify-content: center;
    margin-top: 12px;
  }
}
